$top-navbar-height: 70px;
$side-navbar-width: 240px;
$navbar-height: 70px;
$side-navbar-title-width: 238px;

// Override default variables before the import
$body-bg: #d8d8d8;

$smartphone-width: 576px;
$tablet-width: 768px;
$laptop-width: 992px;
$desktop-width: 1200px;
$big-desktop-width: 1400px;