@import 'variables.scss';

html {
    scroll-padding-top: $navbar-height;
    background-color: white;
}

body {
    overflow-x: hidden;
}

.wrapper {
    background-color: white;
}

.main {
    padding-left: $side-navbar-width;
    
    height: 100vh;
    z-index: 10;

    .top-line {
        margin-left: 30px;
        margin-right: 30px;
    }

    .title {
        padding : 30px;
    }

    .sub-title {
        font-size : 18px;
        margin-left: 20px;
        margin-right: 20px;
        
    }

    .quick-start-title {
        font-weight: bold;
        font-size: 18px;
    }

    .data {
        font-size: 17px;
        font-weight: bold;
        color : rgb(6, 46, 175)
    }

    .data2 {
        font-size: 13px;
        font-weight: bold;
        color : rgb(6, 46, 175)
    }

    .card {
        background-color : white;
        width: 700px;

        .form-group {
            margin-top: 20px;

            label {
                padding-bottom: 10px;
            }

            .form-control {
                background-color : white;
            }
        }

        button {
            margin-top : 30px;
        }
    }

    .sub-list {
        margin-top: 20px;
        
    }


    h1,h2,h3,h4,h5 {
        font-weight: bold;
    }

    ul li {
        font-size: 14px;
        padding-bottom: 10px;
    }

    .small-bold {
        font-size: 15px;
        font-weight: bold;
    }

    .scrollable-area {
        position: absolute;
        width : 1000px;
        border-right: solid 1px gray;

        @media (max-width: $desktop-width) {
            left: $side-navbar-width;
            width : 800px;
        }

        @media (max-width: $big-desktop-width) {
            width : 900px;
        }
    }

    .table {
        

        @media (min-width: $big-desktop-width) {
            width : 900px;
        }
    
        thead th {
            background-color: rgb(175, 175, 175) !important;
            color: white;
        }

        tbody td {
            text-align: center;
        }
        
        th, td {
            width: 200px;
        }

        th {
            text-align: center;
        }

        .name{
            text-align: center;
        }

        td {
            vertical-align: middle;
        }
    }

    .emphasis {
        font-weight: bold;
        font-size: 12px;
    }
    
}


.table > :not(caption) > * > * {
    background-color: white !important;
}

.code-display {
    width : 700px;
    margin-left: 20px;
    background-color: rgb(48, 48, 48);
    color : white;
}