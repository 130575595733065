@import 'variables.scss';

#navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $navbar-height;
    border-bottom: solid 1px gray;
    z-index: 15;
    background-color: blue;
}