@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.footer {
    font-size: 18px;
    width: 100%;
    background :  linear-gradient(180deg, rgb(45, 62, 80) 0%, rgb(45, 62, 80) 100%);
    color: #ffffff;
    padding: 30px 0;
}
  
.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;
}
  
.footer-content {
    display: grid;
    //grid-template-columns: 25% 50% 25%;
    grid-template-columns: 25% 75%;
}

.footer-content .footer-logo {
    font-size: 20px;
}


.footer-address p {
    color: #d6d6d6;
    font-size: 13px;
}
  
.footer-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}
  
.footer-menu li {
    display: inline-block;
    margin-right: 20px;
}
  
.footer-menu li:last-child {
    margin-right: 0;
}
  
.footer-menu a {
    color: #d6d6d6;
    text-decoration: underline;
}
  
.social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.social-icons li {
    display: inline-block;
    margin-right: 10px;
}
  
.social-icons li:last-child {
    margin-right: 0;
}
  
.social-icons a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}
  
.footer-bottom {
    margin-top: 20px;
    text-align: center;
}
  
.footer-bottom p {
    margin: 0;
    font-size: 15px;
}
  
@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
    .footer-menu{
        text-align: left;
    }
    .footer-menu li {
      display: block;
      margin-bottom: 10px;
    }
}